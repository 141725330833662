* {
    margin: 0;
    box-sizing: border-box;
}

input, textarea {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }

body {
    font-family: 'Libre Franklin';
    font-weight: 400;
}

a, a:visited, a:hover, a:active {
    color: inherit;
    text-decoration: none;
}

ul {
    padding: 0;
    list-style-type: none;
    margin-left: 0;
}

.container {
    max-width: 1270px;
    margin: 0 auto;
}

.inner {
    padding: 0px 30px 0px 30px;
}

/* Global Responsive Variables */
@media (max-width: 1000px) {
    .inner {
        padding: 0px 10px 0px 10px
    }
} @media (max-width: 680px) {


} @media (max-width: 320px) {
}