/* cashcard Header */

.cashcard--header {
    display: flex;
    justify-content: space-between;
}

.cashcard--header--brand {
    display: flex;
}

.cashcard--header--brand img {
    padding: 30px 20px 20px 20px;
    height: 100px;
}

.cashcard--header--links {
    display: flex;
    align-items: center;
}
.cashcard--nav--active{
    font-weight: bold;
}

.cashcard--header--links li a {
    margin-left: 30px;
    padding-bottom: 2px;
}

.cashcard--header--links li a:hover {
    color: #dbdbdb;
}
.cashcard--navlinks--mobile {
    display: none;
}

.cashcard--header {
    padding: 0px 20px 0px 20px;
}

@media (max-width: 992px) {
    .cashcard--header--brand img {
        padding: 30px 20px 20px 20px;
    }
    .cashcard--header--links {
        display: none;
    }
    .cashcard--navlinks--mobile {
        display: flex;
        align-items: center;
    } 
    .cashcard--navlinks--mobile img {
        height: 40px;
        width: 40px;
    }
    .fuel--navlinks--mobile {
        display: block;
        display: flex;
    }
    .cashcard--mobile--dropdown {
        position: absolute;
        top: -2000px;
        right: -0px;
        z-index: 1;
    }
    .cashcard--mobile--dropdown a {
        background-color: #fff;
        display: block;
        width: 100vw;
        padding: 20px 0 20px 30px;
        border-top: 1px solid rgb(230, 230, 230);
    }
    .cashcard--mobile--dropdown a:active {
        background-color: whitesmoke;
        outline: none;
        border: 1px solid whitesmoke;
    }

    .cashcard--mobile--dropdown--open {
        animation-name: opencashcard;
        animation-duration: 350ms;
        animation-direction: normal;
        animation-timing-function: ease-out;
        top: 90px;
        opacity: 1;
    } 
    .cashcard--mobile--dropdown--closed {
        animation-name: closecashcard;
        animation-duration: 350ms;
        animation-direction: normal;
        animation-timing-function: ease-in;
        top: -2000px;
        opacity: 0;
    } 

    @keyframes opencashcard {
        from {
            top: 40px;
            opacity: 0;
        } to {
            top: 90px;
            opacity: 1;
        }
    }
    @keyframes closecashcard {
        from {
            top: 90px;
            opacity: 1;
        } to {
            top: 40px;
            opacity: 0;
        }
    }
} @media (max-width: 650px) {

    .cashcard--header--brand img {
        padding: 20px 18px 18px 18px;
        height: 80px;
    }
    .cashcard--header {
        padding: 0px 10px 0px 10px;
    }

    .cashcard--mobile--dropdown--open {
        animation-name: opencashcardSmall;
        animation-duration: 350ms;
        animation-direction: normal;
        animation-timing-function: ease-out;
        top: 80px;
        opacity: 1;
    } 
    .cashcard--mobile--dropdown--closed {
        animation-name: closecashcardSmall;
        animation-duration: 350ms;
        animation-direction: normal;
        animation-timing-function: ease-in;
        top: -2000px;
        opacity: 0;
    } 

    @keyframes opencashcardSmall {
        from {
            top: 40px;
            opacity: 0;
        } to {
            top: 80px;
            opacity: 1;
        }
    }
    @keyframes closecashcardSmall {
        from {
            top: 80px;
            opacity: 1;
        } to {
            top: 40px;
            opacity: 0;
        }
    }
} @media (max-width: 450px) {
    .cashcard--header--brand img {
        padding: 20px 12px 18px 12px;
        height: 70px;
    }
}

/* Section App */
.cashcard--app {
    background: linear-gradient(90deg, #0E2759, #59C6DD);
    padding-top: 30px;
    z-index: 1;
    overflow: hidden;
    min-height: 650px;
}

.cashcard--app--information {
    margin: auto 200px;
    position: relative;
    text-align: center;
}

.cashcard--app--information h1 {
    margin-top: 50px;
    margin-bottom: 50px;
    color: white;
    line-height: 2em;
    font-weight: 400;
    text-align: start;
}

.cashcard--app--information--imgholder {
    display: flex;
    justify-content: center;
}
.cashcard--app--wrapper {
    position: absolute;
}
.cashcard--app--information img {
    width: 500px;
    z-index: 0;
}

@media (max-width: 1000px) {
    .cashcard--app--information {
        margin: 20px;
        min-height: 450px;
    }
    .cashcard--app--information h1 {
        line-height: 1.5em;
    }
    .cashcard--app--wrapper {
        bottom: -130px
    }
    .cashcard--app--information img {
        width: 400px;
    }
} @media (max-width: 600px) {
    .cashcard--app {
        min-height: 310px;
    }
    .cashcard--app--information img {
        width: 300px;
    }
    .cashcard--app--information {
        min-height: 380px;
    }
    .cashcard--app--wrapper {
        bottom: -30px;
    }
    .cashcard--app--information h1 {
        font-size: 20px;
        margin: 20px 0 0px 0;
    }
} @media (max-width: 400px) {
    .cashcard--app--wrapper {
        bottom: -60px;
    }
    .cashcard--app--information img {
        width: 250px;
    }
}



/*cashcard new css*/
/* cashcard Hero */
.cashcard--refer {
    padding: 100px 0 100px 0;
    background-color: #d1ebe2;
}
.cashcard--refer--heading {
    display: flex;
    align-items: center;
}

.cashcard--refer--heading h1 {
    font-size: 32px;
}
.cashcard--sideline {
    display: inline-block;
    width: 175px;
    height: 10px;
    background-color: black;
    vertical-align: middle;
    margin-right: 20px;
}
.cashcard--hero {
    margin-left: 150px;
    display: flex;
}
.cashcard--left{
    padding: 100px 0px 0 50px; 
    background-color: #fff;
    display: flex;
    align-items: flex-start;
    width: 50%;
    height: auto;
}
.cashcard--left ol {
    counter-reset: item;
    font-size: 20px;
    padding-right: 50px;
    font-weight: 200;
    padding-bottom: 30px;
}
.cashcard--left ol li {
    display: block;
    padding-bottom: 20px;
    letter-spacing: 0.91px;
    line-height: 1.3;
}
.cashcard--left ol li:before {
    content: counter(item) ". "; 
    counter-increment: item; 
    font-weight: bold;
}
.cashcard-info {
    margin: 50px 0 5px 0;
    font-weight: 200;
    letter-spacing: 0.5px;
}
.cashcard-info a {
    text-decoration: underline;
    font-size: 18px;
    color: #39c4e6;
} 
.guide-info {
    margin: 0px 0 50px 0;
    font-weight: 200;
    letter-spacing: 0.5px;
}
.guide-info a {
    text-decoration: underline;
    font-size: 18px;
    color: #39c4e6;
} 
.cashcard--link--wrapper {
    margin: 50px 0 50px 0;
}
.cashcard--link--wrapper a {
    border-bottom: 5px solid #000000;
    padding: 10px 50px 10px 40px;
    font-size: 24px;
    font-weight: 700;
}
.cashcard--link--wrapper a:hover {
    border-bottom: 5px solid #fbe40d;
    background: #000000;
    color: #fbe40d
}
.cashcard--link{
    background: #fbe40d;
    text-align: center;
}
.cashcard--right{
    width: 50%;
}
.spotto--cash-img img {
    width: 310px;
    height: 192px;
    object-fit: contain;
    margin: 50px 0px 50px 75px;
    display: flex-end;
}
.cashcard--signin-img img {
    width: 385px;
    height: 209px;
    object-fit: contain;
    margin: 50px 40px 50px 0px;
    }

@media (max-width: 1200px) {
    .cashcard--refer {
        padding: 70px 0 70px 0;
    }
    .cashcard--signin-img img {
        width: 100%;
        margin: 50px 40px 50px 0px;
    }
    .spotto--cash-img img {
        width: 80%;
        margin: 100px 0px 50px 75px;
    }
    .cashcard--link--wrapper a {
        border-bottom: 5px solid #000000;
        padding: 10px 50px 10px 40px;
        font-size: 22px;
        font-weight: 700;
    }
    .cashcard--refer {
        padding: 70px 0 70px 0;
    }

}
@media (max-width: 1000px) {
    .cashcard--refer {
        padding: 50px 0 50px 0;
    }
    .cashcard--hero {
        flex-direction: column;
        margin: 10px 25px 10px 150px;
    }
    .cashcard--hero div {
        width: auto;
    }
    .cashcard--link--wrapper a {
        border-bottom: 5px solid #000000;
        padding: 10px 10px 10px 10px;
        font-size: 20px;
        font-weight: 700;
    }
    .cashcard--signin-img img {
        width: 80%;
    }
    .spotto--cash-img img {
        width: 70%;
         margin: 0px 0px 0px 40px; 
    }
    .cashcard--refer--heading h1 {
        font-size: 26px;
}
    
}
@media (max-width: 680px) {
    .cashcard--hero {
        flex-direction: column;
        margin: 10px 25px 10px 40px;
    }
    .cashcard--link--wrapper a {
        border-bottom: 5px solid #000000;
        padding: 10px 20px 10px 20px;
        font-size: 18px;
        font-weight: 700;
    }
    .cashcard--refer .cashcard--sideline {
        width: 20%;
        height: 7px;
    }
    .cashcard--refer--heading h1 {
        font-size: 24px;
    }
    .cashcard--signin-img img {
        width: 80%;
    }
    .spotto--cash-img img {
        width: 70%;
    }
}
@media (max-width: 440px) {
    .cashcard--left {
        padding: 50px 00px 0 0; 
        background-color: #fff;
        display: flex;
        align-items: flex-start;
        width: 50%;
        height: auto;
    }
    .cashcard--hero {
        flex-direction: column;
    }
    .cashcard--link--wrapper a {
        border-bottom: 5px solid #000000;
        padding: 10px 10px 10px 10px;
        font-size: 18px;
        font-weight: 700;
    }
    .cashcard--signin-img img {
        width: 100%;
    }
    .spotto--cash-img img {
        width: 90%;
    }
    .cashcard--refer--heading h1 {
        font-size: 20px;
    }
    .cashcard--left ol {
        counter-reset: item;
        font-size: 18px;
        padding-right: 50px;
        font-weight: 100;
        line-height: 40px;
        padding-bottom: 30px;
    }
@media (max-width: 320px) {
    .cashcard--link--wrapper a {
        border-bottom: 5px solid #000000;
        font-size: 14px;
        font-weight: 700;
    }
}
}