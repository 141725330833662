/* This file is added by Thanh */
.howtouse--header {
    display: flex;
    justify-content: space-between;
}

.howtouse--header--brand {
    display: flex;
}

.howtouse--header--brand img {
    padding: 30px 20px 20px 20px;
    height: 100px;
}

.howtouse--header--links {
    display: flex;
    align-items: center;
}

.howtouse--header--links li a {
    margin-left: 30px;
    padding-bottom: 2px;
}

.howtouse--header--links li a:hover {
    color: #dbdbdb;
}
.howtouse--navlinks--mobile {
    display: none;
}

.howtouse--header {
    padding: 0px 20px 0px 20px;
}



.howtouse--hero {
    margin-left: 150px;
    display: flex;
}

.howtouse--left{
    padding: 100px 0px 0 50px; 
    background-color: #fff;
    display: flex;
    align-items: flex-start;
    height: auto;
}
.howtouse--left ol {
    counter-reset: item;
    font-size: 20px;
    padding-right: 50px;
    font-weight: 200;
    padding-bottom: 30px;
}
.howtouse--left ol li {
    display: block;
    padding-bottom: 20px;
    letter-spacing: 0.91px;
    line-height: 1.3;
}
.howtouse--left ol li:before {
    content: counter(item) ". "; 
    counter-increment: item; 
    font-weight: bold;
}

.player--wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  }
  
  .react--player {
    position: absolute;
    top: 0;
    left: 0;
  }


@media (max-width: 992px) {
    .howtouse--header--brand img {
        padding: 30px 20px 20px 20px;
    }
    .howtouse--header--links {
        display: none;
    }
    .howtouse--navlinks--mobile {
        display: flex;
        align-items: center;
    } 
    .howtouse--navlinks--mobile img {
        height: 40px;
        width: 40px;
    }
    .fuel--navlinks--mobile {
        display: block;
        display: flex;
    }
    .howtouse--mobile--dropdown {
        position: absolute;
        top: -2000px;
        right: -0px;
        z-index: 1;
    }
    .howtouse--mobile--dropdown a {
        background-color: #fff;
        display: block;
        width: 100vw;
        padding: 20px 0 20px 30px;
        border-top: 1px solid rgb(230, 230, 230);
    }
    .howtouse--mobile--dropdown a:active {
        background-color: whitesmoke;
        outline: none;
        border: 1px solid whitesmoke;
    }
    .howtouse--mobile--dropdown--open {
        animation-name: openhowtouse;
        animation-duration: 350ms;
        animation-direction: normal;
        animation-timing-function: ease-out;
        top: 90px;
        opacity: 1;
    } 
    .howtouse--mobile--dropdown--closed {
        animation-name: closehowtouse;
        animation-duration: 350ms;
        animation-direction: normal;
        animation-timing-function: ease-in;
        top: -2000px;
        opacity: 0;
    } 
    @keyframes openhowtouse {
        from {
            top: 40px;
            opacity: 0;
        } to {
            top: 90px;
            opacity: 1;
        }
    }
    @keyframes closehowtouse {
        from {
            top: 90px;
            opacity: 1;
        } to {
            top: 40px;
            opacity: 0;
        }
    }
} @media (max-width: 650px) {

    .howtouse--header--brand img {
        padding: 20px 18px 18px 18px;
        height: 80px;
    }
    .howtouse--header {
        padding: 0px 10px 0px 10px;
    }

    .howtouse--mobile--dropdown--open {
        animation-name: openhowtouseSmall;
        animation-duration: 350ms;
        animation-direction: normal;
        animation-timing-function: ease-out;
        top: 80px;
        opacity: 1;
    } 
    .howtouse--mobile--dropdown--closed {
        animation-name: closehowtouseSmall;
        animation-duration: 350ms;
        animation-direction: normal;
        animation-timing-function: ease-in;
        top: -2000px;
        opacity: 0;
    } 

    @keyframes openhowtouseSmall {
        from {
            top: 40px;
            opacity: 0;
        } to {
            top: 80px;
            opacity: 1;
        }
    }
    @keyframes closehowtouseSmall {
        from {
            top: 80px;
            opacity: 1;
        } to {
            top: 40px;
            opacity: 0;
        }
    }
} @media (max-width: 450px) {
    .howtouse--header--brand img {
        padding: 20px 12px 18px 12px;
        height: 70px;
    }
}