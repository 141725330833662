* {
    margin: 0;
    box-sizing: border-box;
}

input, textarea {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }

body {
    font-family: 'Libre Franklin';
    font-weight: 400;
}

a, a:visited, a:hover, a:active {
    color: inherit;
    text-decoration: none;
}

ul {
    padding: 0;
    list-style-type: none;
    margin-left: 0;
}

.container {
    max-width: 1270px;
    margin: 0 auto;
}

.inner {
    padding: 0px 30px 0px 30px;
}

/* Global Responsive Variables */
@media (max-width: 1000px) {
    .inner {
        padding: 0px 10px 0px 10px
    }
} @media (max-width: 680px) {


} @media (max-width: 320px) {
}
/* Home Page */
.home {
    background-image: url(/assets/images/combined-yellows.png);
    background-repeat: no-repeat;
    background-color: #fbe40d;
}

/* Home Header */
.home--header {
    padding-top: 40px;
    padding-bottom: 40px;
}

.home--header img {
    width: 120px;
    height: 120px;
}

.home--navmenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 60px 10px 60px;
}

.home--header li {
    list-style-type: none;
}

.home--navlinks {
    font-family: 'Libre Franklin';
    font-weight: 100;
    font-size: 16px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.home--navlinks li a {
    margin-left: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #fff;
}

.home--navlinks li:hover {
    color: white;
}

.home--navlinks--mobile {
    display: none;
}

/* Header Responsive Queries */
/* Break points are at 1270, 1000, 680 and 320 pixels */

@media (max-width: 1000px) {
    .home--header {
        padding: 20px 0 20px 0;
    }
    .home--header img {
        height: 75px;
        width: 75px;
    }
    .home--navmenu {
        margin: 10px 25px 10px 25px;
    }
} @media (max-width: 992px) {
    .home--header {
        padding: 10px 0 10px 0;
    }
    .home--header img {
        height: 60px;
        width: 60px;
    }
    .home--navmenu {
        margin: 10px 20px 10px 20px;
        position: relative;
        z-index: 999;
    }
    .home--navlinks {
        display: none;
    }
    .home--navlinks--mobile {
        display: block;
        display: flex;
    }
    .home--navlinks--mobile img {
        height: 40px;
        width: 40px;
    }
    .mobile--dropdown {
        position: absolute;
        top: -2000px;
        right: -30px;
        z-index: -1;
    }
    .mobile--dropdown a {
        background-color: #fff;
        display: block;
        width: 100vw;
        padding: 20px 0 20px 30px;
        border-top: 1px solid rgb(230, 230, 230);
    }
    .mobile--dropdown a:active {
        background-color: whitesmoke;
        outline: none;
        border: 1px solid whitesmoke;
    }

    .mobile--dropdown--open {
        -webkit-animation-name: open;
                animation-name: open;
        -webkit-animation-duration: 350ms;
                animation-duration: 350ms;
        -webkit-animation-direction: normal;
                animation-direction: normal;
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        top: 80px;
        opacity: 1;
    } 
    .mobile--dropdown--closed {
        -webkit-animation-name: close;
                animation-name: close;
        -webkit-animation-duration: 350ms;
                animation-duration: 350ms;
        -webkit-animation-direction: normal;
                animation-direction: normal;
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        top: -2000px;
        opacity: 0;
    } 

    @-webkit-keyframes open {
        from {
            top: 40px;
            opacity: 0;
        } to {
            top: 80px;
            opacity: 1;
        }
    } 

    @keyframes open {
        from {
            top: 40px;
            opacity: 0;
        } to {
            top: 80px;
            opacity: 1;
        }
    }
    @-webkit-keyframes close {
        from {
            top: 80px;
            opacity: 1;
        } to {
            top: 40px;
            opacity: 0;
        }
    }
    @keyframes close {
        from {
            top: 80px;
            opacity: 1;
        } to {
            top: 40px;
            opacity: 0;
        }
    }
} @media (max-width: 320px) {
    .home--navmenu {
        margin: 10px 10px 10px 10px;
    }
    .mobile--dropdown {
        right: -20px;
    }
}

/* Home Hero */
.home--hero--inner {
    margin: 0px 60px 0px 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.home--hero--inner h1 {
    font-size: 90px;
    font-weight: 900;
}

.home--hero--inner h2 {
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 20px;
    font-weight: 'semibold';
    margin: 20px 0px 20px 0px;
}

.home--learnmore {
    text-transform: uppercase;
    margin: 30px 0px 30px 0px;
}

.home--learnmore a:hover {
    color: whitesmoke;
    text-decoration: none;
    -webkit-animation-name: hoverLink;
            animation-name: hoverLink;
    -webkit-animation-duration: 250ms;
            animation-duration: 250ms; 
}

.home--hero--inner p {
    margin: 20px 0px 20px 0px;
    font-size: 22px;
    line-height: 36px;
    font-weight: 100;
}

.home--storelinks {
    display: flex;
    margin-top: 30px;
    margin-bottom: 60px;
}

.home--storelinks li{
    margin-right: 20px;;
}

.home--hero--left--terminal {
    display: none;
}

/* Hero Responsive Queries */
@media (max-width: 1270px) {
    .home--hero--right img {
        width: 40vw
    }
} @media (max-width: 1000px) {
    .home--hero--inner {
        margin: 10px 25px 10px 25px;
    }
    .home--hero--left h1 {
        font-size: 60px;
    }
    .home--hero--left h2 {
        font-size: 15px;
    }
    .home--hero--left p {
        font-size: 16px;
        margin: 10px 0 10px 0;
        line-height: 27px;
    }
    .home--hero--left img {
        height: 50px;
    }
    .home--learnmore {
        margin: 20px 0 20px 0;
    }
} @media (max-width: 680px) {
    .home--hero--left h1 {
        font-size: 50px;
    }
    .home--hero--left h2 {
        font-size: 12px;
        margin: 15px 0 10px 0;
    }
    .home--hero--left p {
        font-size: 16px;
        margin: 10px 0 10px 0;
        line-height: 30px;
    }
    .home--hero--left img {
        height: 50px;
    }
    .home--hero--right {
        display: none;
    }
    .home--hero--left--terminal{
        display: block;
        margin: 10px 0 10px 0;
        text-align: center;
    }
    .home--hero--left--terminal img {
        width: 45vw;
        height: auto;
        height: initial;
    }
    .home--hero--inner {
        margin: 10px;
    }
    .home--hero--left--holder {
        display: flex;
        justify-content: space-between
    }
    .home--hero--left--heading {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
} @media (max-width: 440px) {
    .home--hero--inner {
        margin: none;
    }
    .home--hero--left p {
        font-size: 14px;
    }
    .home--hero--left h1 {
        font-size: 40px;
    }
    .home--hero--left--terminal img {
        width: 140px;
    }
    .home--hero--left--holder {
        justify-content: flex-start;
    }
    .home--hero--left--heading {
        margin-right: 30px;
    }
    .home--storelinks {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .home--storelinks {
        margin-bottom: 20px;
    }
    .home--storelinks li {
        margin-bottom: 20px;
    }
    .home--storelinks img {
        height: 60px;
    }
} @media (max-width: 380px) {
    .home--hero--left--heading {
        margin-right: 20px;
    }
} @media (max-width: 360px) {
    .home--hero--left--heading {
        margin-right: 15px;
    }
    .home--hero--left h1 {
        font-size: 35px;
    }
} @media (max-width: 340px) {
    .home--hero--left h1 {
        font-size: 30px;
    }
}

/* Section Home How */
.home--how {
    background-color: #d1ebe2;
    padding: 100px 0px 0px 0px;
}

.home--how--heading {
    display: flex;
    align-items: center;
    margin-bottom: 120px;
}

.home--sideline {
    display: inline-block;
    width: 175px;
    height: 10px;
    background-color: black;
    vertical-align: middle;
    margin-right: 20px;
}

.home--how--heading h1 {
    font-size: 32px;
}

.home--features  {
    display: flex;
    background: #fff;
    justify-content: space-evenly;
    padding-bottom: 20px;
}

.home--feature--item {
    position: relative;
    padding: 10px;
    margin: 10px;
    flex-basis: 0;
    flex-grow: 1;
}

.home--feature--item .holder-img {
    background: #fff;
    display: inline-block;
    border-radius: 100px;
    padding: 25px;
    position: absolute;
    top: -60px;
    left: 30%;
}

.home--feature--item img {
    height: 65px;
    width: 65px;
}

.home--feature--item h1 {
    margin-top: 60px;
    margin-bottom: 10px;
}

.home--feature--item h2 {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1.75em;
}

.home--feature--item p {
    font-size: 20px;
    font-weight: 100;
    line-height: 1.5em
}

.home--feature--item .holder-h1 {
    height: 85px;
}

.home--feature--item .holder-h2 {
    height: 85px;
}

.home--shellcard {
    border-bottom: 1px solid #333;
    padding-bottom: 3px;
}

.home--shellcard:hover {
    border-bottom: 1px solid whitesmoke;
    color: whitesmoke;
}

@media (max-width: 1000px) {
    .home--feature--item img {
        width: 50px;
        height: 50px;
    }
    .home--feature--item h1 {
        font-size: 20px;
        margin-top: 30px;
    }
    .home--feature--item .holder-h1{
        height: 60px;
    }
    .home--feature--item .holder-h2{
        height: 60px;
    }
    .home--feature--item h2 {
        font-size: 12px;
    }
    .home--feature--item p {
        font-size: 16px;
    }
} @media (max-width: 940px) {
    .home--how {
        padding-top: 60px;
    }
    .home--how h1 {
        font-size: 26px;
    }
    .home--features  {
        display: block;
        padding: 10px 0 10px 0;
    }
    .home--feature--item {
        margin: 10px 10px 30px 10px;
        display: flex;
        flex-direction: column;
        border: 1px solid #d1ebe2;
    }
    .home--feature--item .holder-h1{
        height: auto;
        margin-bottom: 15px;
    }
    .home--feature--item h1 {
        margin-top: 15px;
    }
    .home--feature--item .holder-h2{
        height: auto;
        margin-bottom: 10px;
    }
    .holder-img {
        border: 1px solid #5a5a5a;
    }
    .home--feature--item .holder-img {
        position: static;
        position: initial;
        margin: 10px auto;
    }
    .home--how--heading {
        margin-bottom: 60px;
    }
} @media (max-width: 680px) {
    .home--sideline {
        width: 20%;
        height: 7px;
    }
    .home--how--heading h1 {
        font-size: 20px;
    }
}


/* Section Home Here */
.home--here {
    display: flex;
    justify-content: flex-end
}

.home--here--left {
    width: 50%;
    background-image: url(/assets/images/left.png);
    background-repeat: no-repeat;
    padding: 20px;
    background-size: 100% 100%;
    position: relative;
    background-color: #fff;
}

.home--here--left--terminal {
    position: absolute;
    left: 20%;
    bottom: 0%
}

.home--here--left img {
    height: 400px;
    width: 320px;
}

.home--here--right {
    width: 50%;
    background-image: url(/assets/images/right1.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 40px;
}

.home--here--right h1 {
    margin: 50px 0px 50px 20px;
    font-size: 30px;
}

.how--holder {
    display: flex;
    margin-bottom: 40px;
}

.how--holder img {
    height: 70px;
    margin: 0px 20px 0px 20px;
}

.how--holder p {
    font-size: 24px;
    font-weight: 100;
    margin-top: 5px;
}

.terminal--overlay {
    position: absolute;
    top: 180px;
    left: 25%;
}

.terminal--overlay h1 {
    margin-bottom: 20px;
}

.terminal--overlay--small {
    display: none;
}

@media (max-width: 1000px) {
    .home--here {
        flex-direction: column
    }
    .home--here--left {
        width: auto;
        position: static;
        position: initial;
        padding-top: 50px;
    }
    .home--here--right {
        width: auto;
    }
    .home--here--left--terminal {
        margin: 20px 0 20px 0;
        position: relative;
        left: auto;
        left: initial;
        text-align: center;
        bottom: 10px;
    }

    .home--here--left img {
        width: 200px;
        height: 250px;
    }
    .terminal--overlay--large {
        display: none;
    }
    .terminal--overlay--small {
        display: inline-block;
        position: relative;
        top: 50px;
    }
    .terminal--overlay--small--text {
        position: absolute;
        left: 32px;
        top: 90px;
    }
    .terminal--overlay--small--text h1 {
        font-size: 20px;
        margin-bottom: 20px;
    }
} @media (max-width: 680px) {
    .home--here--left {
        padding-top: 0px;
    }
    .home--here--right {
        padding: 20px;
    }
    .home--here--right h1 {
        font-size: 20px;
    }
    .how--holder img {
        height: 50px;
    }
    .how--holder p {
        font-size: 18px;
    }
} @media (max-width: 400px) {
    .home--here--right {
        padding: 10px;
    }
    .how--holder img {
        height: 30px;
    }
}

/* Section Refer a friend */
.home--refer {
    padding: 100px 0 100px 0;
    background-color: #d1ebe2;
}
.home--refer--heading {
    display: flex;
    align-items: center;
}

.home--refer--heading h1 {
    font-size: 32px;
}

.home--refer--subheading {
    margin: 30px 20px 0px 200px;
}

.refer--error {
    margin-top: 10px;
    color: tomato;
    -webkit-animation-name: revealError;
            animation-name: revealError;
    -webkit-animation-duration: 1000ms;
            animation-duration: 1000ms;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
            animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    display: inline-block;
}

.refer--error--hidden {
    display: none;
}

@-webkit-keyframes revealError {
    from {
        margin-top: -10px;
        opacity: 0;
        display: none;
    } to {
        margin-top: 10px;
        opacity: 1;
        display: inline-block;
    }
}

@keyframes revealError {
    from {
        margin-top: -10px;
        opacity: 0;
        display: none;
    } to {
        margin-top: 10px;
        opacity: 1;
        display: inline-block;
    }
}

.home--refer--subheading p {
    font-size: 24px;
    font-weight: 100;
    line-height: 42px;
    margin: 0px 40px 20px 0;
}

.home--refer form {
    display: flex;
    justify-content: center;
    padding-top : 30px;
}

.home--refer form fieldset {
    border: none;
    max-width: 900px;
    width: 80%;
}

form h2 {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    display: block;
    margin: 10px 0 10px 0;
}

form label {
    margin-right: 20px;
    margin-bottom: 15px;
}

.refer--column {
    padding: 20px 0px 20px 0px;
    display: flex;
    justify-content: space-between;
}

form input {
    padding: 10px;
    height: 52px;
    width: 350px;
    font-size: 24px;
    border: 2px solid #38c4e4;
    font-weight: normal;
    background-color: transparent;
}

form input[type="submit"] {
    background-color: #38c4e4;
    border-bottom: 5px solid black;
    font-weight: 700;
}

form input[type="submit"]:hover {
    background-color: #7E7E7E;
    border: none;
    border-bottom: 5px solid #38c4e4;
    color: #38c4e4;
    outline: none;
}

form input:focus {
    outline: none;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
}

form input[type="submit"]:hover {
    cursor: pointer;
}

.refer--input {
    display: flex;
    flex-direction: column;
}

.refer--meta {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.home--refer .home--sideline {
    margin-right: 25px;
}

.refer--feedback {
    display: none;
    color: tomato;
}

.show--feedback {
    display: block;
}

.success h3 {
    color: #24B24B;
}


@media (max-width: 1000px) {
    .home--refer {
        padding: 50px 0 50px 0;
    }
    .refer--column {
        flex-direction: column;
        padding: 0px;
    }
    form .refer--input {
        margin-bottom: 40px;
    }
    .refer--input input {
        width: 100%;
    }
    form h2 {
        font-size: 16px;
        margin-bottom: 30px;
    }


} @media (max-width: 680px) {
    .home--refer .home--sideline {
        width: 20%;
        height: 7px;
    }
    .home--refer--heading h1 {
        font-size: 20px;
    }
    .home--refer--subheading {
        margin-left: calc(20% + 25px);
        margin-right: 10px;
    }
    .home--refer--subheading p {
        font-size: 18px;
        margin-right: 10px;
    }
    .home--refer form fieldset {
        width: 90%;
    }
    form input {
        width: 90%;
    }
    /* Captcha */
    .refer--meta {
        flex-direction: column;
    }
    .refer--form--captcha {
        margin-bottom: 30px;
    }
    .refer--form--submit input {
        width: 100%;
    }
} @media (max-width: 400px) {
    .home--refer--subheading {
        margin: 20px 20px 20px 20px;
    }
    .home--refer--subheading p {
        font-size: 15px;
    }
    .home--refer--heading {
        padding-right: 10px;
    }
}

/* Home Fares */

.home--fares {
    display: flex;
    justify-content: space-evenly;
    min-height: 650px;
}

.home--fares--left {
    padding: 100px 30px 0 0;
    background-color: #fff;
    display: flex;
    align-items: flex-start;
    width: 50%;
    height: auto;
}

.home--fares--left span {
    margin-top: 15px;
}

.home--fares--left--meta h1 {
    margin-bottom: 50px;
    font-size: 32px;
}

.home--fares--left--meta p {
    font-size: 24px;
    padding-right: 50px;
    margin: 50px 0px 50px 0px;
    font-weight: 100;
    line-height: 40px;
}

.home--fares--left--meta a {
    background: #fbe40d;
    border-bottom: 5px solid #000000;
    padding: 10px 50px 10px 50px;
    font-size: 24px;
    margin: 0px 0 100px 0;
    font-weight: 700;
}

.home--fares--left--meta a:hover {
    border-bottom: 5px solid #fbe40d;
    background: #000000;
    color: #fbe40d
}

.home--fares--right {
    background-image: url(/assets/images/phones.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 50%;
}

@media (max-width: 1270px) {
    .home--fares {
        height: auto;
    }
    .home--fares--left {
        width: 50%;
        padding-top: 60px
    }
    .home--fares--right {
        width: 50%
    }

} @media (max-width: 1000px) {
    .home--fares {
        flex-direction: column;
    }
    .home--fares div {
        width: auto;
    }
    .home--fares--right {
        width: 100%;
        height: 100vw
    }
    .home--fares--left {
        padding-bottom: 60px;
    }
    .home--fares--left p {
        font-size: 20px;
        margin: 20px 0 50px 0;
    }
    .home--fares--left h1 {
        font-size: 20px;
    }
    .home--fares--left span {
        margin-top: 7.5px
    }
    .home--fares--left--meta a {
        margin-top: 30px;
    }
} @media (max-width: 680px) {
    .home--fares--left p {
        font-size: 16px;
        margin: 0px 0 50px 0;
    }
    .home--fares--left {
        padding-right: 10px;
    }
    .home--fares--left .home--sideline {
        width: 30%;
    }
}

/* Contact Us */
.home--contact {
    padding: 50px 0px 50px 0px
}

.home--contact--wrap {
    margin: 50px 0px 50px 0px;
    display: flex;
    align-items: center;
}

.home--contact--wrap h1 {
    font-size: 32px;
}

.home--contact--inner {
    max-width: 880px;
    margin: 0 auto;
}

.home--contact--type {
    display: flex;
    justify-content: space-between
}

.home--contact--type div {
    width: 300px;
}

.home--contact--type h2 {
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.home--contact--type p, .home--contact--type a {
    font-size: 24px;
    font-weight: 100;
}

.home--contact--signup {
    margin-top: 40px;
    font-size: 16px;
}

.home--contact--location {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.home--contact--location div {
    width: 300px;
}

.home--contact--location--item h2 {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.home--contact--location--item div {
    margin-bottom: 30px;
}

.home--contact--location--item p {
    font-size: 20px;
    font-weight: 100;
}

@media (max-width: 1000px) {
    .home--contact--inner {
        padding: 0 20px 0 20px;
    }
    .home--contact {
        padding: 0px 0px 20px 0;
    }
} @media (max-width: 680px) {
    .home--contact--wrap .home--sideline {
        width: 20%
    }
    .home--contact--wrap h1 {
        font-size: 20px;
    }
    .home--contact--type {
        flex-direction: column;
    }
    .home--contact--type div {
        margin-bottom: 30px;
    }
    .home--contact--type a {
        font-weight: 500;
    }
    .home--contact--signup {
        margin-top: 20px;
    }
    .home--contact--location {
        flex-direction: column;
    }
    .home--contact--wrap {
        margin-top: 40px;
    }
} 

/* Home footer  */
.home--footer {
    background: #EEEEEE;
    padding: 50px 30px 50px 30px;
}

.home--footer p {
    font-size: 12px;
    font-weight: 100;
    margin-bottom: 5px;
}

.home--footer div {
    margin-bottom: 30px;
}

.home--footer a {
    text-decoration: underline;
    font-size: 12px;
}

@media (max-width: 650px) {
    .home--footer p {
        margin-bottom: 20px;
    }
}

/* Home Refer Form loader */
.refer--loader {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .refer--loader div {
    display: inline-block;
    position: absolute;
    left: 6px;
    width: 13px;
    background: #fff;
    -webkit-animation: refer--loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
            animation: refer--loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .refer--loader div:nth-child(1) {
    left: 6px;
    -webkit-animation-delay: -0.24s;
            animation-delay: -0.24s;
  }
  .refer--loader div:nth-child(2) {
    left: 26px;
    -webkit-animation-delay: -0.12s;
            animation-delay: -0.12s;
  }
  .refer--loader div:nth-child(3) {
    left: 45px;
    -webkit-animation-delay: 0;
            animation-delay: 0;
  }
  @-webkit-keyframes refer--loader {
    0% {
      top: 6px;
      height: 51px;
    }
    50%, 100% {
      top: 19px;
      height: 26px;
    }
  }
  @keyframes refer--loader {
    0% {
      top: 6px;
      height: 51px;
    }
    50%, 100% {
      top: 19px;
      height: 26px;
    }
  }

/* Spotto Cash Card */

 .home--cashcard {
     display: flex; 
    position: relative;
} 
.home--cashcard--heading {
    display: flex;
    align-items: center;
}

.home--cashcard--left {
    padding: 100px 0px 0 0; 
    background-color: #fbe40d;
    display: flex;
    align-items: flex-start;
    width: 50%;
    height: auto;
}

.home--cashcard--left span {
    margin-top: 15px;
}

 .home--cashcard--left--meta h1 {
    margin-bottom: 50px;
    font-size: 32px;
} 

.home--cashcard--left--meta h2 {
    font-size: 20px;
    margin: 20px 0 20px 0; 
    line-height: 1.9;
    letter-spacing: 0.5px; 
}

.home--cashcard--left ul {
    list-style-type: disc;
    font-size: 22px;
     padding-right: 45px; 
     padding-left: 31px; 
    font-weight: 200;
    line-height: 2.18;
    letter-spacing: 1px;
}
 .home--cashcard--right {
    overflow: hidden;
    
} 
.spotto--cashcard-img {
    position: absolute;
    top: 0%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.spotto--cashcard-img img {
      width: 100%;  
    margin: 75px 20px 0px 0px;
    }
.home--cashcard--right {
    position: relative;
    width: 50%;
    height: auto; 
}
.home--cashcard--right svg{
    height: 100%;  
    width: 100%; 
}
.spotto--cashcard--information {
    position: absolute;
    font-size: 12px;
    font-weight: 200;
    bottom: 0;
     /* left: 2%;  */
}
.home--cashcard--right--background polygon {
    background: #fff;
    position: absolute;
}
.home--cashcard--right p {
    margin: 20px 0px 20px 0px;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 200;
    letter-spacing: 0.5px;
}
.cashcard-learn-more {
    margin: 0px 0 35px 0;
    padding-left: 45px;
    position: absolute;
    bottom: 0;
}

.cashcard-learn-more a {
    text-decoration: underline;
    font-size: 22px;
    font-weight: 200;
    color: #39c4e6;
    position: relative; 
    letter-spacing: 0.5px;  
} 
.cashcard-learn-more--resp {
        display: none;
}
hr{ 
    height: 4px;
    color: #fff;
    background-color:#fff;
    border: none;
}
@media (max-width: 1270px) {
    .home--cashcard {
        height: auto;
    }
    .home--cashcard--left {
        width: 50%;
        padding-top: 60px;
        padding-bottom: 80px;
    }
    .home--cashcard--right {
        width: 50%;
    }
    .spotto--cashcard-img {
        position: absolute;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .spotto--cashcard-img img {
         width: 85%;
    }
    .cashcard-learn-more--resp {
        display: none;
    }

} @media (max-width: 1000px) {
    .home--cashcard {
         flex-direction: column;
    }
    .home--cashcard div {
        width: auto;
    }
      .home--cashcard--left--meta h2 {
        font-size: 22px;
    }
     .home--cashcard--left--meta h1 {
        font-size: 32px;
    } 
    .home--cashcard--left {
        padding-bottom: 20px
    }
    .home--cashcard--left span {
        margin-top: 7.5px
    }
    .home--cashcard--left--meta ul {
        font-size: 24px;
    }
    .home--cashcard--left--meta a {
        margin-top: 30px;
    }
     .spotto--cashcard-img {
        position: absolute;
        top: 8%;
        left: 10%;
        right: 10%;
    }
    .spotto--cashcard-img img {
        width: 70%;
         margin: 0px; 
    } 
    .home--cashcard--right {      
        top: 5px; 
    }    
    .home--cashcard--right svg {
         width: 100vw;
         height: calc(100vw - 28vh)
    } 
    .cashcard-learn-more {
        display: none;
    }
    .cashcard-learn-more--resp {
        display: block;
        padding-left: 12px;
    }
    .cashcard-learn-more--resp a {
        text-decoration: underline;
        font-size: 20px;
        font-weight: 200;
        letter-spacing: 0.5px;
        color: #39c4e6;
        position: relative;
    } 
    .spotto--cashcard--information {
        top: 85%;
        left: 19%;
        bottom: 0%;
    }
    
    
} @media (max-width: 680px) {
    .home--cashcard--left--meta h2 {
        font-size: 18px;
    }
     .home--cashcard--left--meta h1 {
        margin-bottom: 50px;
        font-size: 22px;
}  
    .home--cashcard--left--meta ul {
        font-size: 18px;
    }
    .home--cashcard--left .home--sideline {
        width: 30%;
    }
    .spotto--cashcard-img {
        position: absolute;
         top: 25%;  
         bottom: 90px; 
    }
     .spotto--cashcard-img img {
        width: 70%;
    } 
    .home--cashcard--right svg {
         width: 100vw;
         height: calc(100vw - 22vh)
    } 
    
    .home--cashcard--right p {
        margin: 0px 5px 20px 20px;
        font-size: 12px;
        line-height: 15px;
        font-weight: 200;
    }
    .cashcard-learn-more--resp {
        display: block;
        padding-left: 12px;
    }
    .cashcard-learn-more--resp a {
        text-decoration: underline;
        font-size: 20px;
        font-weight: 200;
        color: #39c4e6;
        position: relative;
        display:block;
} 
    .spotto--cashcard--information {
        top: 85%; 
        left: 16%;
        bottom: 0%
    }
}

@media (max-width: 440px) {

    .home--cashcard--right p {
        font-weight: 100;
        font-size: 10px;
    }
    .home--cashcard--right svg {
         width: 100vw;
         height: calc(100vw - 10vh)
    } 
    .spotto--cashcard--information {
        left: 0%;
        bottom: 0%;
    }
    .spotto--cashcard-img {
        position: absolute;
        top: 35%; 
        bottom: 90px;
    }
}
@media (max-width: 320px) {
    .home--cashcard--right p {
        font-size: 10px;
        font-weight: 200;
        line-height: 12px;
    }
    .spotto--cashcard--information {
        position: absolute;
        bottom: 0;
    }
    .home--cashcard--right svg {
         width: 100vw;
         height: calc(100vw - 10vh)
    } 
}


/* cashcard Header */

.cashcard--header {
    display: flex;
    justify-content: space-between;
}

.cashcard--header--brand {
    display: flex;
}

.cashcard--header--brand img {
    padding: 30px 20px 20px 20px;
    height: 100px;
}

.cashcard--header--links {
    display: flex;
    align-items: center;
}
.cashcard--nav--active{
    font-weight: bold;
}

.cashcard--header--links li a {
    margin-left: 30px;
    padding-bottom: 2px;
}

.cashcard--header--links li a:hover {
    color: #dbdbdb;
}
.cashcard--navlinks--mobile {
    display: none;
}

.cashcard--header {
    padding: 0px 20px 0px 20px;
}

@media (max-width: 992px) {
    .cashcard--header--brand img {
        padding: 30px 20px 20px 20px;
    }
    .cashcard--header--links {
        display: none;
    }
    .cashcard--navlinks--mobile {
        display: flex;
        align-items: center;
    } 
    .cashcard--navlinks--mobile img {
        height: 40px;
        width: 40px;
    }
    .fuel--navlinks--mobile {
        display: block;
        display: flex;
    }
    .cashcard--mobile--dropdown {
        position: absolute;
        top: -2000px;
        right: -0px;
        z-index: 1;
    }
    .cashcard--mobile--dropdown a {
        background-color: #fff;
        display: block;
        width: 100vw;
        padding: 20px 0 20px 30px;
        border-top: 1px solid rgb(230, 230, 230);
    }
    .cashcard--mobile--dropdown a:active {
        background-color: whitesmoke;
        outline: none;
        border: 1px solid whitesmoke;
    }

    .cashcard--mobile--dropdown--open {
        -webkit-animation-name: opencashcard;
                animation-name: opencashcard;
        -webkit-animation-duration: 350ms;
                animation-duration: 350ms;
        -webkit-animation-direction: normal;
                animation-direction: normal;
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        top: 90px;
        opacity: 1;
    } 
    .cashcard--mobile--dropdown--closed {
        -webkit-animation-name: closecashcard;
                animation-name: closecashcard;
        -webkit-animation-duration: 350ms;
                animation-duration: 350ms;
        -webkit-animation-direction: normal;
                animation-direction: normal;
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        top: -2000px;
        opacity: 0;
    } 

    @-webkit-keyframes opencashcard {
        from {
            top: 40px;
            opacity: 0;
        } to {
            top: 90px;
            opacity: 1;
        }
    } 

    @keyframes opencashcard {
        from {
            top: 40px;
            opacity: 0;
        } to {
            top: 90px;
            opacity: 1;
        }
    }
    @-webkit-keyframes closecashcard {
        from {
            top: 90px;
            opacity: 1;
        } to {
            top: 40px;
            opacity: 0;
        }
    }
    @keyframes closecashcard {
        from {
            top: 90px;
            opacity: 1;
        } to {
            top: 40px;
            opacity: 0;
        }
    }
} @media (max-width: 650px) {

    .cashcard--header--brand img {
        padding: 20px 18px 18px 18px;
        height: 80px;
    }
    .cashcard--header {
        padding: 0px 10px 0px 10px;
    }

    .cashcard--mobile--dropdown--open {
        -webkit-animation-name: opencashcardSmall;
                animation-name: opencashcardSmall;
        -webkit-animation-duration: 350ms;
                animation-duration: 350ms;
        -webkit-animation-direction: normal;
                animation-direction: normal;
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        top: 80px;
        opacity: 1;
    } 
    .cashcard--mobile--dropdown--closed {
        -webkit-animation-name: closecashcardSmall;
                animation-name: closecashcardSmall;
        -webkit-animation-duration: 350ms;
                animation-duration: 350ms;
        -webkit-animation-direction: normal;
                animation-direction: normal;
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        top: -2000px;
        opacity: 0;
    } 

    @-webkit-keyframes opencashcardSmall {
        from {
            top: 40px;
            opacity: 0;
        } to {
            top: 80px;
            opacity: 1;
        }
    } 

    @keyframes opencashcardSmall {
        from {
            top: 40px;
            opacity: 0;
        } to {
            top: 80px;
            opacity: 1;
        }
    }
    @-webkit-keyframes closecashcardSmall {
        from {
            top: 80px;
            opacity: 1;
        } to {
            top: 40px;
            opacity: 0;
        }
    }
    @keyframes closecashcardSmall {
        from {
            top: 80px;
            opacity: 1;
        } to {
            top: 40px;
            opacity: 0;
        }
    }
} @media (max-width: 450px) {
    .cashcard--header--brand img {
        padding: 20px 12px 18px 12px;
        height: 70px;
    }
}

/* Section App */
.cashcard--app {
    background: -webkit-linear-gradient(left, #0E2759, #59C6DD);
    background: linear-gradient(90deg, #0E2759, #59C6DD);
    padding-top: 30px;
    z-index: 1;
    overflow: hidden;
    min-height: 650px;
}

.cashcard--app--information {
    margin: auto 200px;
    position: relative;
    text-align: center;
}

.cashcard--app--information h1 {
    margin-top: 50px;
    margin-bottom: 50px;
    color: white;
    line-height: 2em;
    font-weight: 400;
    text-align: start;
}

.cashcard--app--information--imgholder {
    display: flex;
    justify-content: center;
}
.cashcard--app--wrapper {
    position: absolute;
}
.cashcard--app--information img {
    width: 500px;
    z-index: 0;
}

@media (max-width: 1000px) {
    .cashcard--app--information {
        margin: 20px;
        min-height: 450px;
    }
    .cashcard--app--information h1 {
        line-height: 1.5em;
    }
    .cashcard--app--wrapper {
        bottom: -130px
    }
    .cashcard--app--information img {
        width: 400px;
    }
} @media (max-width: 600px) {
    .cashcard--app {
        min-height: 310px;
    }
    .cashcard--app--information img {
        width: 300px;
    }
    .cashcard--app--information {
        min-height: 380px;
    }
    .cashcard--app--wrapper {
        bottom: -30px;
    }
    .cashcard--app--information h1 {
        font-size: 20px;
        margin: 20px 0 0px 0;
    }
} @media (max-width: 400px) {
    .cashcard--app--wrapper {
        bottom: -60px;
    }
    .cashcard--app--information img {
        width: 250px;
    }
}



/*cashcard new css*/
/* cashcard Hero */
.cashcard--refer {
    padding: 100px 0 100px 0;
    background-color: #d1ebe2;
}
.cashcard--refer--heading {
    display: flex;
    align-items: center;
}

.cashcard--refer--heading h1 {
    font-size: 32px;
}
.cashcard--sideline {
    display: inline-block;
    width: 175px;
    height: 10px;
    background-color: black;
    vertical-align: middle;
    margin-right: 20px;
}
.cashcard--hero {
    margin-left: 150px;
    display: flex;
}
.cashcard--left{
    padding: 100px 0px 0 50px; 
    background-color: #fff;
    display: flex;
    align-items: flex-start;
    width: 50%;
    height: auto;
}
.cashcard--left ol {
    counter-reset: item;
    font-size: 20px;
    padding-right: 50px;
    font-weight: 200;
    padding-bottom: 30px;
}
.cashcard--left ol li {
    display: block;
    padding-bottom: 20px;
    letter-spacing: 0.91px;
    line-height: 1.3;
}
.cashcard--left ol li:before {
    content: counter(item) ". "; 
    counter-increment: item; 
    font-weight: bold;
}
.cashcard-info {
    margin: 50px 0 5px 0;
    font-weight: 200;
    letter-spacing: 0.5px;
}
.cashcard-info a {
    text-decoration: underline;
    font-size: 18px;
    color: #39c4e6;
} 
.guide-info {
    margin: 0px 0 50px 0;
    font-weight: 200;
    letter-spacing: 0.5px;
}
.guide-info a {
    text-decoration: underline;
    font-size: 18px;
    color: #39c4e6;
} 
.cashcard--link--wrapper {
    margin: 50px 0 50px 0;
}
.cashcard--link--wrapper a {
    border-bottom: 5px solid #000000;
    padding: 10px 50px 10px 40px;
    font-size: 24px;
    font-weight: 700;
}
.cashcard--link--wrapper a:hover {
    border-bottom: 5px solid #fbe40d;
    background: #000000;
    color: #fbe40d
}
.cashcard--link{
    background: #fbe40d;
    text-align: center;
}
.cashcard--right{
    width: 50%;
}
.spotto--cash-img img {
    width: 310px;
    height: 192px;
    object-fit: contain;
    margin: 50px 0px 50px 75px;
    display: flex-end;
}
.cashcard--signin-img img {
    width: 385px;
    height: 209px;
    object-fit: contain;
    margin: 50px 40px 50px 0px;
    }

@media (max-width: 1200px) {
    .cashcard--refer {
        padding: 70px 0 70px 0;
    }
    .cashcard--signin-img img {
        width: 100%;
        margin: 50px 40px 50px 0px;
    }
    .spotto--cash-img img {
        width: 80%;
        margin: 100px 0px 50px 75px;
    }
    .cashcard--link--wrapper a {
        border-bottom: 5px solid #000000;
        padding: 10px 50px 10px 40px;
        font-size: 22px;
        font-weight: 700;
    }
    .cashcard--refer {
        padding: 70px 0 70px 0;
    }

}
@media (max-width: 1000px) {
    .cashcard--refer {
        padding: 50px 0 50px 0;
    }
    .cashcard--hero {
        flex-direction: column;
        margin: 10px 25px 10px 150px;
    }
    .cashcard--hero div {
        width: auto;
    }
    .cashcard--link--wrapper a {
        border-bottom: 5px solid #000000;
        padding: 10px 10px 10px 10px;
        font-size: 20px;
        font-weight: 700;
    }
    .cashcard--signin-img img {
        width: 80%;
    }
    .spotto--cash-img img {
        width: 70%;
         margin: 0px 0px 0px 40px; 
    }
    .cashcard--refer--heading h1 {
        font-size: 26px;
}
    
}
@media (max-width: 680px) {
    .cashcard--hero {
        flex-direction: column;
        margin: 10px 25px 10px 40px;
    }
    .cashcard--link--wrapper a {
        border-bottom: 5px solid #000000;
        padding: 10px 20px 10px 20px;
        font-size: 18px;
        font-weight: 700;
    }
    .cashcard--refer .cashcard--sideline {
        width: 20%;
        height: 7px;
    }
    .cashcard--refer--heading h1 {
        font-size: 24px;
    }
    .cashcard--signin-img img {
        width: 80%;
    }
    .spotto--cash-img img {
        width: 70%;
    }
}
@media (max-width: 440px) {
    .cashcard--left {
        padding: 50px 00px 0 0; 
        background-color: #fff;
        display: flex;
        align-items: flex-start;
        width: 50%;
        height: auto;
    }
    .cashcard--hero {
        flex-direction: column;
    }
    .cashcard--link--wrapper a {
        border-bottom: 5px solid #000000;
        padding: 10px 10px 10px 10px;
        font-size: 18px;
        font-weight: 700;
    }
    .cashcard--signin-img img {
        width: 100%;
    }
    .spotto--cash-img img {
        width: 90%;
    }
    .cashcard--refer--heading h1 {
        font-size: 20px;
    }
    .cashcard--left ol {
        counter-reset: item;
        font-size: 18px;
        padding-right: 50px;
        font-weight: 100;
        line-height: 40px;
        padding-bottom: 30px;
    }
@media (max-width: 320px) {
    .cashcard--link--wrapper a {
        border-bottom: 5px solid #000000;
        font-size: 14px;
        font-weight: 700;
    }
}
}
/* This file is added by Thanh */
.howtouse--header {
    display: flex;
    justify-content: space-between;
}

.howtouse--header--brand {
    display: flex;
}

.howtouse--header--brand img {
    padding: 30px 20px 20px 20px;
    height: 100px;
}

.howtouse--header--links {
    display: flex;
    align-items: center;
}

.howtouse--header--links li a {
    margin-left: 30px;
    padding-bottom: 2px;
}

.howtouse--header--links li a:hover {
    color: #dbdbdb;
}
.howtouse--navlinks--mobile {
    display: none;
}

.howtouse--header {
    padding: 0px 20px 0px 20px;
}



.howtouse--hero {
    margin-left: 150px;
    display: flex;
}

.howtouse--left{
    padding: 100px 0px 0 50px; 
    background-color: #fff;
    display: flex;
    align-items: flex-start;
    height: auto;
}
.howtouse--left ol {
    counter-reset: item;
    font-size: 20px;
    padding-right: 50px;
    font-weight: 200;
    padding-bottom: 30px;
}
.howtouse--left ol li {
    display: block;
    padding-bottom: 20px;
    letter-spacing: 0.91px;
    line-height: 1.3;
}
.howtouse--left ol li:before {
    content: counter(item) ". "; 
    counter-increment: item; 
    font-weight: bold;
}

.player--wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  }
  
  .react--player {
    position: absolute;
    top: 0;
    left: 0;
  }


@media (max-width: 992px) {
    .howtouse--header--brand img {
        padding: 30px 20px 20px 20px;
    }
    .howtouse--header--links {
        display: none;
    }
    .howtouse--navlinks--mobile {
        display: flex;
        align-items: center;
    } 
    .howtouse--navlinks--mobile img {
        height: 40px;
        width: 40px;
    }
    .fuel--navlinks--mobile {
        display: block;
        display: flex;
    }
    .howtouse--mobile--dropdown {
        position: absolute;
        top: -2000px;
        right: -0px;
        z-index: 1;
    }
    .howtouse--mobile--dropdown a {
        background-color: #fff;
        display: block;
        width: 100vw;
        padding: 20px 0 20px 30px;
        border-top: 1px solid rgb(230, 230, 230);
    }
    .howtouse--mobile--dropdown a:active {
        background-color: whitesmoke;
        outline: none;
        border: 1px solid whitesmoke;
    }
    .howtouse--mobile--dropdown--open {
        -webkit-animation-name: openhowtouse;
                animation-name: openhowtouse;
        -webkit-animation-duration: 350ms;
                animation-duration: 350ms;
        -webkit-animation-direction: normal;
                animation-direction: normal;
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        top: 90px;
        opacity: 1;
    } 
    .howtouse--mobile--dropdown--closed {
        -webkit-animation-name: closehowtouse;
                animation-name: closehowtouse;
        -webkit-animation-duration: 350ms;
                animation-duration: 350ms;
        -webkit-animation-direction: normal;
                animation-direction: normal;
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        top: -2000px;
        opacity: 0;
    } 
    @-webkit-keyframes openhowtouse {
        from {
            top: 40px;
            opacity: 0;
        } to {
            top: 90px;
            opacity: 1;
        }
    } 
    @keyframes openhowtouse {
        from {
            top: 40px;
            opacity: 0;
        } to {
            top: 90px;
            opacity: 1;
        }
    }
    @-webkit-keyframes closehowtouse {
        from {
            top: 90px;
            opacity: 1;
        } to {
            top: 40px;
            opacity: 0;
        }
    }
    @keyframes closehowtouse {
        from {
            top: 90px;
            opacity: 1;
        } to {
            top: 40px;
            opacity: 0;
        }
    }
} @media (max-width: 650px) {

    .howtouse--header--brand img {
        padding: 20px 18px 18px 18px;
        height: 80px;
    }
    .howtouse--header {
        padding: 0px 10px 0px 10px;
    }

    .howtouse--mobile--dropdown--open {
        -webkit-animation-name: openhowtouseSmall;
                animation-name: openhowtouseSmall;
        -webkit-animation-duration: 350ms;
                animation-duration: 350ms;
        -webkit-animation-direction: normal;
                animation-direction: normal;
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        top: 80px;
        opacity: 1;
    } 
    .howtouse--mobile--dropdown--closed {
        -webkit-animation-name: closehowtouseSmall;
                animation-name: closehowtouseSmall;
        -webkit-animation-duration: 350ms;
                animation-duration: 350ms;
        -webkit-animation-direction: normal;
                animation-direction: normal;
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        top: -2000px;
        opacity: 0;
    } 

    @-webkit-keyframes openhowtouseSmall {
        from {
            top: 40px;
            opacity: 0;
        } to {
            top: 80px;
            opacity: 1;
        }
    } 

    @keyframes openhowtouseSmall {
        from {
            top: 40px;
            opacity: 0;
        } to {
            top: 80px;
            opacity: 1;
        }
    }
    @-webkit-keyframes closehowtouseSmall {
        from {
            top: 80px;
            opacity: 1;
        } to {
            top: 40px;
            opacity: 0;
        }
    }
    @keyframes closehowtouseSmall {
        from {
            top: 80px;
            opacity: 1;
        } to {
            top: 40px;
            opacity: 0;
        }
    }
} @media (max-width: 450px) {
    .howtouse--header--brand img {
        padding: 20px 12px 18px 12px;
        height: 70px;
    }
}
